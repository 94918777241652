.product_carousel_card {
    border: 2px solid #555555;
    border-radius: 20px;
    width: 300px;
}

.product_carousel_card {
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
}

.product_carousel_price_row {
    padding-left: 10px;
    padding-top: 20px;
}

.product_carousel_name_row {
    font-size: 24px;
}

.product_carousel_price_col {
    /*background: black;*/
    color: white;
    font-size: 18px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.modal-content {
    border-radius: 1rem !important;
}

.modal-header {
    border-bottom: none !important;
    padding-top: 2rem !important;
    padding-right: 2rem !important;
}

.product_carousel_image {
    height: 200px;
    width: 200px;
}

.product_detail_image {
    height: 300px;
    width: 300px;
}

.mrkt-pladce-button {
    background: #84004C;
    color: white;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 16px !important;
    border: 1px solid #84004C;

    font-family: 'TradeGothic LT CondEighteen', sans-serif !important;
    font-style: normal;
    font-weight: 300;
    font-size: 18pt !important;
    text-decoration: none;
}

.product_carousel_btnBuy_row button {
    min-width: 160px;
}

.product_carousel_detail_row .btn-link {
    color: #84004C;
    font-size: 14px;
}

.details_modal_more_details_col .btn-link {
    color: #84004C;
    font-size: 18px;
}

.product_carousel_btnBuy_row, .details_modal_btnBuy_col {
    font-size: 18pt;
}

.details_modal_name_col {
    font-size: 24px;
}

.details_modal_price_data_col, .details_modal_price_label_col {
    font-size: 18pt;
}

.details_modal_details_col {
    font-family: montserrat-regular;
    line-height: 25px;
    font-weight: 300;
}

.detail_modal_contents_row {
    padding-bottom: 50px;
}

.detail_modal_contents_main_col {
    max-width: 500px !important;
}

.details_modal_name_col, .details_modal_price_label_col, .details_modal_more_details_col > div {
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    font-style: normal;
    font-weight: 500;
    text-decoration: none;
}

.details_modal_more_details_col > div {
    background: #222227;
    color: white;
    font-size: 18pt;
    border-radius: 8px;
    border: #222227 1px solid;
}

.details_modal_more_details_col_medium > div {
    font-family: 'TradeGothic LT CondEighteen';
    background: #222227;
    color: white;
    font-size: 14pt;
    border-radius: 8px;
    border: #222227 1px solid;
}

.pay_modal_config_amount_col, .pay_modal_config_wallet_label_col {
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18pt;
    text-decoration: none;
}

.pay_modal_config_descr_col, .pay_modal_config_wallet_balance_col, .pay_modal_confirm_label {
    font-size: 16pt;
}

.product_buy_wallet_input {
    border-radius: 6px;
    border: 1px #959595 solid;
    padding: 8px;
}

.pay_modal_confirm_title, .pay_modal_confirm_amount, .pay_modal_success_col {
    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18pt;
    text-decoration: none;
}

.pay_modal_confirm_cancel_col button {
    background: white;
    color: #84004C;
}

.pay_modal_success_icon {
    height: 200px;
    width: 200px;
}

.toggleContainer .toggle {
    background: white !important;
    border-radius: 25px;
    border: solid black 1px;
}

.toggleContainer .toggleOption {
    color: black !important;
    height: 50px !important;
    line-height: 50px !important;

    font-family: 'TradeGothic LT CondEighteen', sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14pt !important;
    text-decoration: none;
}

.toggleContainer {
    border-radius: 25px;
    background: #f5f5f5 !important;
    /*border: black solid 1px !important;*/
}